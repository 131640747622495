import React, { useEffect, useState } from 'react';
import images from '../assets/images';

function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % images.sliderImages.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []); 

  return (
    <div id="controls-carousel" className="relative w-full" data-carousel="static">
      <div className="relative h-16 md:h-96">
        {images.sliderImages.map((item, index) => (
          <div key={index} className={`carousel-item ${index === activeIndex ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500`} data-carousel-item>
            <img src={item} className="absolute opacity-50 inset-0 w-full h-full object-cover" alt={`Carousel ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
