import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-orange-700 to-pink-900 pt-0.5">
      <div className="w-9/12 mx-auto my-0 min-h-20">
      <div className="text-center mt-4 md:mt-2">
          <p className="font-playfair text-l text-white">
            Call Us:{" "}
            <a
              href="tel:+91 8237436176"
              className="font-semibold text-orange-300 hover:text-white transition"
            >+91 8237436176
            </a>
          </p>
        </div>
        <SocialMediaIcons />
        <div className="md:flex justify-center md:justify-between text-center">
        
          <p className="font-playfair font-semibold text-3xl bg-opacity-80 text-white">
            GET4GROW
          </p>
          
          <p className="font-playfair text-l bg-opacity-50 text-white">
            All Rights Reserved.
          </p>
        </div>
  
      </div>
    </footer>
  );
};

export default Footer;
